<template>
	<div>
		<v-tooltip bottom open-on-hover max-width="300">
			<template v-slot:activator="{ on, attrs }">
				<div v-bind="attrs" v-on="on">
					<v-btn
						raised
						rounded
						color="success"
						:loading="loadingParent"
						outlined
						target="_blank"
						class="my-4 text-center"
						@click="callAttendWebsite()"
					>
						<span>{{ $t('events.goToMeeting') }} </span>
						<v-icon>mdi-open-in-new</v-icon>
					</v-btn>
				</div>
			</template>
			<span v-if="notYetStarted">{{ $t('events.notYetStarted') }}</span>
			<span v-else-if="hasFinished">{{ $t('events.hasFinished') }}</span>
		</v-tooltip>
		<p class="text-center">
			<span style="color: #FF6F00">
				<v-icon>mdi-account-group</v-icon>
				{{ event.attendances.numTotal > 0 ? event.attendances.numTotal : '--' }} / {{ event.attendances.maxCapacity }}
			</span>
		</p>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'EventAttendWebsiteButton',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		notYetStarted() {
			if (!this.event) return true
			let now = Date.now()
			let start = new Date(this.event.info.timestamp.start)
			return (start - now) / (1000 * 60) > 15
		},
		hasFinished() {
			if (!this.event) return true
			let now = Date.now()
			let end = new Date(this.event.info.timestamp.end)
			return (now - end) / (1000 * 60) > 15
		},
		...mapGetters({
			event: 'events/event'
		})
	},
	methods: {
		callAttendWebsite() {
			window.open(this.event.info.website, '_blank')
		}
	}
}
</script>
